<template>
    <section class="hero is-medium is-fullheight is-bold">
        <div class="hero-body">
            <div class="container has-text-centered">
                <slot></slot>
            </div>
        </div>
    </section>
</template>

<script>
    export default {
        name: "ErrorLayout"
    }
</script>

<style scoped>

</style>