<template>
  <section class="section">
    <content-placeholders
        v-if="loading"
    >
      <content-placeholders-heading :img="true" />
      <content-placeholders-text :lines="3" />
    </content-placeholders>
    <order-data v-else :order="data" @refresh-order="prepareComponent">
      <div v-if="data.invoice && !data.is_general_public">
        <div class="is-divider" data-content="Facturas"></div>
        <b-field grouped>
          <p class="control">
            <strong>RFC: </strong> {{ data.invoice.rfc }} <br>
            <strong>Serie: </strong> {{ data.invoice.serie }} <br>
            <strong>Folio: </strong> {{ data.invoice.folio }} <br>
            <strong>Fecha: </strong> {{ data.invoice.datetime }} <br>
            <b-tooltip label="Ver Factura">
              <button type="button" class="button is-danger" @click="showPDF = true">
                <b-icon icon="file-pdf"></b-icon>
              </button>
            </b-tooltip>
          </p>
        </b-field>
        <div class="columns">
          <div class="column is-3">
            <b-field label="Email">
              <b-input v-model="email"/>
            </b-field>
          </div>
          <div class="column is-3">
            <b-field>
              <b-button class="button is-primary" @click="sendEmail">Enviar por correo</b-button>
            </b-field>
          </div>
        </div>

        <b-modal v-model="showPDF">
          <iframe class="lapi__iframe" :src="`${url}/order-invoice/${data.invoice.uuid}/pdf/download`"></iframe>
        </b-modal>
      </div>
    </order-data>
  </section>
</template>

<script>
import OrderData from "../../components/order/OrderData";
export default {
  name: "ShowOrder",
  components: {OrderData},
  props: {
    id: String| Number,
  },
  data() {
    return {
      data: {},
      apiUrl: 'orders',
      loading: true,
      showPDF: false,
      email: ''
    }
  },
  computed: {
    url() {
      return process.env.VUE_APP_API_URL;
    }
  },
  watch: {
    id: 'prepareComponent'
  },
  mounted() {
    this.prepareComponent();
  },
  methods: {
    async prepareComponent() {
      this.loading = true;
      await this.getData(this.id, {withEmailTax: true})
          .then(({data}) => {
            this.data = data || {};
            this.email = this.data.tax_email || '';
          }, error => {
            this.$toast.error('Ocurrió un problema')
          });
      this.loading = false;
    },
    sendEmail(){
      this.$loader.show();
      this.$http.post(`/invoice/email/${this.data.id}`, {email: this.email}).then(({data})=> {
        this.$toast.success(data.message);
      }).catch(() => {
      }).finally(() => {
        this.$loader.hide();
      })
    }
  }
}
</script>

<style scoped>
.lapi__iframe{
  width: 100%;
  height: 80vh;
}
</style>
