<template>
  <div>
    <div class="is-divider" data-content="Paciente"></div>
    <p><strong>Nombre:</strong> {{ patient.full_name }}</p>
    <p><strong>Edad:</strong> {{ patient.full_age }}&nbsp;&nbsp;<strong>Fecha de nacimiento: </strong> {{ patient.birthday }}</p>
    <p><strong>Género:</strong> {{ patient.gender_str }}</p>
  </div>
</template>

<script>
export default {
  name: "OrderDataPatient",
  props: {
    patient: Object
  }
}
</script>

<style scoped>

</style>