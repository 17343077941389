<template>
  <section class="section">
    <content-placeholders
        v-if="isLoading"
    >
      <content-placeholders-heading :img="true"/>
      <content-placeholders-text :lines="3"/>
    </content-placeholders>
    <template v-else-if="(!data.invoice || data.invoice.is_general_public) && !invoice">
      <order-data :order="data"
                  :patient="patient"
                  :tax-data="taxData"
                  billed
                  :has-tax-data="hasTaxData"
                  :errors-tax-data="errorsTaxData">
      </order-data>
      <br>
      <b-field label="Observaciones">
        <b-input v-model="observations"  type="textarea"></b-input>
      </b-field>
      <b-field grouped>
        <p class="control">
          <button class="button is-primary" type="button" @click="sendAndUpdate">
            Guardar Datos Fiscales Y Generar Factura
          </button>
        </p>
        <p class="control">
          <button
              v-if="false"
              class="button is-primary is-outlined"
              type="button" @click="onStoreBilled">
            Solo Generar Factura
          </button>
        </p>
      </b-field>
    </template>
    <template v-else-if="!invoice">
      <div class="box">
        <h1 class="title">
          Factura de la orden
        </h1>
        <div class="columns is-multiline">
          <div class="column is-12">
            <p>
              <strong>RFC: </strong> {{ data.invoice.rfc }} <br>
              <strong>Serie: </strong> {{ data.invoice.serie }} <br>
              <strong>Folio: </strong> {{ data.invoice.folio }} <br>
              <strong>Fecha: </strong> {{ data.invoice.datetime }} <br>
            </p>

          </div>
          <div class="column is-12">
            <iframe class="lapi__iframe" :src="`${endpoint}/order-invoice/${data.invoice.uuid}/pdf/download`"></iframe>
          </div>
          <p>
            <a @click="$router.back()">{{ $t('common.pages.back_to_home') }}</a>
          </p>
        </div>
      </div>
    </template>
    <template v-if="invoice">
      <div class="box">
        <div class="columns is-multiline">
          <div class="column is-12">
            <h1 class="title">Factura generada</h1>
            <p>
              <strong>RFC: </strong> {{ invoice.rfc }} <br>
              <strong>Serie: </strong> {{ invoice.serie }} <br>
              <strong>Folio: </strong> {{ invoice.folio }} <br>
              <strong>Fecha: </strong> {{ invoice.datetime }} <br>
            </p>
          </div>
          <div class="column is-12">
            <iframe class="lapi__iframe" :src="`${endpoint}/order-invoice/${invoice.uuid}/pdf/download`"></iframe>
          </div>
        </div>
      </div>
    </template>
  </section>
</template>

<script>
import OrderData from "../../components/order/OrderData";
import UpdatePatientTaxDataForm from "@/components/patient/forms/UpdatePatientTaxDataForm";
import ErrorLayout from "@/components/layout/ErrorLayout";

export default {
  name: "BillingOrder",
  components: {UpdatePatientTaxDataForm, OrderData, ErrorLayout},
  props: {
    id: String | Number,
  },
  data() {
    return {
      data: {sat_version:'3.3'},
      apiUrl: 'orders',
      isLoading: true,
      taxData: {},
      errorsTax: {},
      patient: '',
      hasTaxData: true,
      invoice: null,
      in_process: false,
      observations: ''
    }
  },
  computed: {
    errorsTaxData() {
      let errors = {};
      for (let item in this.errorsTax) {
        let itemKey = item.split('.');
        if (itemKey.length > 1) {
          errors[itemKey[1]] = this.errorsTax[item];
        } else {
          errors[itemKey[0]] = this.errorsTax[item];
        }

      }
      return errors;
    },
    endpoint() {
      return process.env.VUE_APP_API_URL;
    }
  },
  watch: {
    id: 'prepareComponent'
  },
  mounted() {
    this.prepareComponent();
  },
  methods: {
    async prepareComponent() {
      this.isLoading = true;
      await this.getOrder();
      await this.getPatientTaxData();
      this.taxData = Object.assign({}, this.taxData, {sat_version: '3.3'});
      this.isLoading = false;
    },
    getOrder() {
      return this.getData(this.id, {
        add: 'taxData', minified: true
      })
          .then(({data}) => {
            this.data = data || {};
            this.patient = data.patient_code;
          }, error => {
            console.log(error);
          });
    },
    getPatientTaxData() {
      return this.$http.get(`patient-tax-datum/${this.patient}`)
          .then(({data}) => {
            this.taxData = data.data || {};
            this.taxData.use = 'D01';
            this.hasTaxData = true;
          }, ({response}) => {
            this.hasTaxData = false;
            let data = response.data;
            this.taxData = {
              fiscal_name: this.data.patient.full_name,
              use: 'D01'
            }
          });
    },
    onUpdateTaxData() {
      this.errorsTax = {};
      return this.$http.put(`patient-tax-datum/${this.patient}`, this.taxData)
          .then(({data}) => {
            this.$toast.success(data.message);
            return data;
          }).catch(({response}) => {
            let data = response.data;
            this.$toast.error(data.message);
            this.errorsTax = data.errors;
            return data;
          })
    },
    onStoreTaxData() {
      this.errorsTax = {};
      this.taxData.patient = this.patient;
      return this.$http.post(`patient-tax-datum`, this.taxData)
          .then(({data}) => {
            this.$toast.success(data.message);

            return data;
          }).catch(({response}) => {
            let data = response.data;
            this.$toast.error(data.message);
            this.errorsTax = data.errors;
            return data;
          })
    },
    onStoreBilled() {
      this.errorsTax = {};
      this.errors = {};
      let data = {
        order: this.id,
        tax_data: this.taxData
      }

      if(this.observations){
        data['tax_data']['observations'] = this.observations;
      }

      this.$loader.show();
      this.$toast.info('Generando factura espere un momento');
      return this.$http.post('order-billed', data)
          .then(({data}) => {
            this.$toast.success(data.message);
            this.invoice = data.invoice;
            return data;
          }, ({response}) => {
            let data = response.data;
            this.$toast.error(data.message);
            this.errorsTax = data.errors;
          }).finally(() => {
            this.$loader.hide();
          });
    },
    async sendAndUpdate() {
      if(!this.in_process){
        this.in_process = true;
        this.$loader.show();
        if (this.hasTaxData) {
          await this.onUpdateTaxData();
        } else {
          await this.onStoreTaxData();
        }
        await this.onStoreBilled();
        this.$loader.hide();
        this.in_process = false;
      }
    }
  }
}
</script>

<style scoped>
.lapi__iframe {
  width: 100%;
  height: 60vh;
}
</style>
