<template>
    <table>
        <thead>
        <tr>
            <th>Código</th>
            <th>Estudio</th>
            <th v-if="showPrice">Precio</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="item in studies">
            <td>{{ item.code }}</td>
            <td>{{ item.name }}</td>
            <td v-if="showPrice">{{ item.price }}</td>
        </tr>
        </tbody>
    </table>
</template>

<script>
    export default {
        name: "OrderDataStudies",
        props: {
            studies: Array,
          showPrice: Boolean
        },
    }
</script>

<style scoped>

</style>